import styled from "styled-components";
import '../../../styles/global.css';
import {Link} from "gatsby";

//background for background color
//borderColor for border color
//color for text color


export const DrvButton = styled(Link)`
  display: flex;
  background: ${({background}) => background || 'var(--primary-color)'};
  white-space: nowrap;
  border:2px solid ${({bordercolor}) => bordercolor || 'var(--primary-color)'};
  padding: 4px 35px;
  color: ${({color}) => color || '#fff'};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  min-width: 100px;
  line-height: 38px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s !important;
  border-radius: 50px;
  align-items: center;
  &:hover{
    background: ${({hoverbackground}) => hoverbackground || '#fff'};
    border-color: ${({hoverborder}) => hoverborder || '#fff'};
    color: ${({hovercolor}) => hovercolor || '#545454'};;
  }
`;

