/**
 * DrvSubFooter is shared Component which is used on common page of this Website.
 * Contains description part and one button.
 *
 * To use this component you need to specify description commonly is question, button, background-color
 * and color of the text.
 **/

import React from "react";

import {Container, Description, Action} from './DrvSubFooter.styles';

const DrvSubFooter = ({description, button, background, color}) => {
    return (
        <Container background={background} color={color}>
            <div className='container mx-auto flex flex-col md:flex-row'>
                <Description className='flex basis-9/12 items-center'>
                    {
                        description
                    }
                </Description>
                <Action className='flex basis-3/12 md:justify-center items-center'>
                    {
                        button
                    }
                </Action>
            </div>
        </Container>
    );
}

export default DrvSubFooter;
