import styled from "styled-components"

export const Container = styled.div`
  color: ${({color}) => color};
  background-color: ${({background}) => background};
  padding: 55px 0px;
`

export const Description = styled.div`
  font-size: 32px;
  font-weight: 300;
`

export const Action = styled.div`
  padding: 20px 0px;
`


