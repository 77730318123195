import React from "react";

import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {Text} from "../Financing/Financing.styles";

import {InsuranceData} from "./Insurance.data";
import Cover from "../../../images/Solutions/Insurance/Cover.jpg";

const Insurance = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={InsuranceData[0].title}
                description={InsuranceData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="container mx-auto py-14 flex flex-col lg:flex-row justify-between space-x-0 space-y-7 lg:space-x-7 lg:space-y-0">
                <Text>
                    {InsuranceData[1].content}
                </Text>

                <Text>
                    {InsuranceData[2].content}
                </Text>

                <Text>
                    {InsuranceData[3].content}
                </Text>
            </div>

            {/*CONTENT*/}
            <div className='pb-12 flex flex-col justify-center items-center drv-description' style={{paddingLeft: '45px', paddingRight: '45px'}}>
                <div className='mb-7 font-medium'>
                    {InsuranceData[4].content}
                </div>
                <DrvButton to="https://intrepiddirect.com/industries/franchise-restaurant-insurance/delivery/"
                           background="transparent"
                           color="#545454"
                           hoverbackground="var(--primary-color)"
                           hoverborder="var(--primary-color)"
                           hovercolor="#fff"
                           className="w-fit"
                >
                    CLICK HERE
                </DrvButton>
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                description={InsuranceData[5].content}
                button={
                    <DrvButton to="/request-demo" background="var(--secondary-color)" bordercolor="white" className="w-fit">REQUEST A DEMO</DrvButton>
                }
                color="#ffffff"
                background="#fe5a5e"
            />

        </>
    )
}

export default Insurance;
