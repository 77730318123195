import React from "react";

const InsuranceData = [
    {
        id: 0,
        title: `PARTNERS IN PROTECTION — ENHANCING DELIVERY SERVICES WITH INTREPID DIRECT INSURANCE`,
        content: 'At Drivosity, we\'ve partnered with Intrepid Direct Insurance, uniting our shared passions for innovation, safety, and community service. Together, we are dedicated to transforming the restaurant delivery service industry by integrating advanced telematics technology with comprehensive insurance solutions, ensuring not just savings, but safer roads and stronger communities.',
        location: `/insurance`,
        data: null,
    },
    {
        id: 1,
        title: ``,
        content: (
            <>
                <div className='title'>
                    INTREPID DIRECT INSURANCE + DRIVOSITY = MORE SAVINGS
                </div>
                <div className='paragraph'>
                    Our partnership with Intrepid Direct was founded on a shared commitment to safety and cost efficiency. Intrepid Direct brings a deep understanding of the delivery restaurant industry to our collaboration. They have crafted insurance coverages that not only meet franchisor requirements but also provide robust protection, particularly with their hired and non-owned auto coverage. Leveraging Drivosity's advanced telematics, <span style={{fontWeight: 500}}>Intrepid Direct offers an unmatched discount of up to 25% on Hired & Non-Owned Auto insurance to our clients.</span> This fusion of direct insurance and innovative technology ensures significant savings and heightened safety for every delivery.
                </div>
            </>
        ),
        location: `/insurance`,
        data: null,
    },
    {
        id: 2,
        title: ``,
        content: (
            <>
                <div className='title'>
                    A TAILORED APPROACH TO RISK MANAGEMENT AND COMPLIANCE
                </div>
                <div className='paragraph'>
                    Our partnership goes beyond financial benefits, focusing intensely on risk management. Drivosity's CORE & EDGE platforms, which employ cutting-edge GPS and AI technologies, allow us to monitor and improve driver performance meticulously. Intrepid Direct's insurance offerings are built around these capabilities, ensuring your business meets and exceeds all coverage regulations with a single, streamlined plan designed around your specific needs.
                </div>
            </>
        ),
        location: `/insurance`,
        data: null,
    },
    {
        id: 3,
        title: ``,
        content: (
            <>
                <div className='title'>
                    PROTECTING WHAT MATTERS MOST
                </div>
                <div className='paragraph'>
                    Together, Drivosity and Intrepid Direct Insurance are not just providers; we are partners in protecting what matters most to you. Our collaborative approach ensures that every delivery not only meets high standards of safety and efficiency but also contributes positively to the community. With our comprehensive solutions, you can focus on growing your business, knowing that every aspect, from compliance to driver safety, is covered under our watchful eyes.
                </div>
            </>
        ),
        location: `/financing`,
        data: null,
    },
    {
        id: 4,
        title: ``,
        content: 'Learn more about how Intrepid Direct Insurance can help safeguard your business.',
        location: `/insurance`,
        data: null,
    },
    {
        id: 5,
        title: ``,
        content: 'Want to learn more?',
        location: `/insurance`,
        data: null,
    },
];

export {InsuranceData};
