import styled from "styled-components"

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: var(--text-color);
  
  & .paragraph {
    margin-bottom: 30px;
  }

  & .title {
    color: var(--subtitle-color);
    font-size: 30px;
    margin-bottom: 30px;
    
    &::before {
      content: '';
      border-bottom: 3px solid #0e6faa;
      width: 50px;
      display: block;
      margin-bottom: 33px;
      border-radius: 2px;
    }
  }
  
  & .points {
    margin-bottom: 12px;
    line-height: 26px;
    padding-left: 14px;
  }
`

export const Image = styled.img`
    width: 100%;
    height: fit-content;
    margin-bottom: 20px;
`

export const Points = styled.div`
  margin-bottom: 30px;
  padding: 0 30px;
`
